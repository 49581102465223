import { getR4cDispatcherUrl } from '../../config';
import { IPraxisBranding, ISharedSecuredFiles, ISharedTasks } from '../../../../types/js';
import { RoseApi } from '../apiTypes';

export function createPapoApi(api: RoseApi) {
  return {
    getShareInfos: (shareId: string) =>
      api.r4cDispatch.get<{
        shareIdValid: boolean;
        expires?: string;
        tooManyFailedLoginAttempts: boolean;

        needsToCreatePapoUser: boolean;
        email?: string;

        documentCount: number;
        taskCount: number;
        anamneseTaskCount: number;
        documentTaskCount: number;
        eweTaskCount: number;

        praxisName?: string;
        cid?: string;
        r4chost?: string;
        praxisBranding?: IPraxisBranding;
      }>(`papo/shareInfos/${shareId}`),

    createPapoAccount: (params: { shareId: string; password: string }) =>
      api.r4cDispatch.post<void>(`papo/createPapoAccount`, params),

    resetPassword: (params: { shareId: string }) => api.r4cDispatch.post<void>(`papo/resetPassword`, params),
    resetPasswordConfirm: (params: { shareId: string; resetSecret: string }) =>
      api.r4cDispatch.post<void>(`papo/resetPasswordConfirm`, params),

    papoLogin: (params: { shareId: string; password: string }) =>
      api.r4cDispatch.post<{ token: string }>(`papo/papoLogin`, params),

    getShareEntries: () =>
      api.r4cDispatch.get<{
        unlockedEntries: { fullName: string; documentEntries: ISharedSecuredFiles[]; taskEntries: ISharedTasks[] }[];
        lockedEntries: { fullName: string; count: number; shareId: string }[];
      }>(`papo/getShareEntries`),

    linkpatient: (params: { shareId: string; dob: string; accountOwner: boolean }) =>
      api.r4cDispatch.post<{ success: boolean }>(`papo/linkpatient`, params),

    getDownloadLink: (fileId: string, token: string) => `${getR4cDispatcherUrl()}/papo/download/${fileId}?jwt=${token}`,

    // praxis admin / desktop-r4c routes
    adminGetSharedDocumentsInfos: () =>
      api.r4cDispatch.get<{ entries: ISharedSecuredFiles[]; suppressions: any[] }>(
        `${getR4cDispatcherUrl()}/ssf/adminGetSharedDocumentsInfos`,
      ),
    adminGetSharedTasks: () =>
      api.r4cDispatch.get<{ entries: ISharedTasks[]; suppressions: any[] }>(
        `${getR4cDispatcherUrl()}/ssf/adminGetSharedTasksInfos`,
      ),
    checkMailSendIds: (messageIds: string[]) =>
      api.r4cDispatch.post<Record<string, 'Sent' | string>>(`${getR4cDispatcherUrl()}/ssf/checkMailSendIds`, {
        messageIds,
      }),
    adminResentShareEmailDocument: (shareId: string, overrideMail: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminResentShareEmailDocument`, {
        shareId,
        overrideMail,
      }),
    adminResentShareEmailTask: (shareId: string, overrideMail: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminResentShareEmailTask`, { shareId, overrideMail }),
    adminResetShareIdDocument: (shareId: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminResetShareIdDocument`, { shareId }),
    adminResetShareIdTask: (shareId: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminResetShareIdTask`, { shareId }),
    adminDeleteSharedFile: (fileId: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminDeleteSharedFile`, { fileId }),
    adminDeleteSharedTask: (taskId: string) =>
      api.r4cDispatch.post<void>(`${getR4cDispatcherUrl()}/ssf/adminDeleteSharedTask`, { taskId }),
    adminGetSharedFile: (fileId: string) =>
      api.r4cDispatch.post<{ base64: string }>(`${getR4cDispatcherUrl()}/ssf/adminGetSharedFile`, { fileId }),
  };
}
